<template>
  <div class="auth-layout ui fluid container">
    <main class="main-layout">
      <router-view />
    </main>
    <t-footer />
  </div>
</template>

<script>
import TFooter from '@/components/footer'

export default {
  name: 'TAuthLayout',
  beforeMount () {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push({ name: 'files' })
    }
  },
  components: {
    TFooter
  }
}
</script>

<style lang="scss">
html,
body,
.auth-layout {
  height: 100vh;
}

.auth-layout {
  background-color: #fff;

  label.validate.error {
    color: #fd9093;
    display: block;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
  }

  .alert.error {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    p {
      font-size: 16px;
      margin: 0;
    }

    p, svg {
      color: #fd9093;
    }

    svg {
      margin-right: 10px;
    }
  }

  .main-layout,
  .main-layout > .grid {
    height: calc(100vh - 66px);
  }

  p {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $black-0;
  }

  .main-layout .column {
    max-width: 300px;
  }
}
</style>
